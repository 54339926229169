import { FiltreEntity } from "../entities/arababul/FiltreEntity";

import Api from "./Api";

export const ArabaBulDataServis = {
  GetFiltreler: async function () {
    const response = await Api.get<FiltreEntity[]>("filtre");

    const sonuc = response.data;

    return sonuc;
  },

  SaveFiltre: async function (model: FiltreEntity) {
    const response =
      model.rowKey.length > 0
        ? await Api.put<FiltreEntity>("filtre", model)
        : await Api.post<FiltreEntity>("filtre", model);

    const sonuc = response.data;
    return sonuc;
  },

  DeleteFiltre: async function (rowKey: string) {
    const response = await Api.delete<FiltreEntity>("filtre?rowKey=" + rowKey);
    const sonuc = response.data;
    return sonuc;
  },
};
