import {
  EuiButton,
  EuiDataGrid,
  EuiFieldNumber,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiI18nNumber,
  EuiPanel,
  EuiTable,
  EuiTableBody,
  EuiTableRow,
  EuiTableRowCell,
} from "@elastic/eui";
import React, { Fragment, useCallback, useState } from "react";
import { DataServis } from "../../data/DataServis";
import { RaporVM } from "../../entities/RaporVM";

const columns = [
  {
    id: "aciklama",
  },
  {
    id: "maliyet",
  },
  {
    id: "satisTutar",
  },
  {
    id: "kar",
  },
  {
    id: "satildiMi",
  },
  {
    id: "st",
  },
];

export default function RaporPage() {
  const [raporVM, setRaporVM] = useState<RaporVM>();

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 30,
  });
  const onChangeItemsPerPage = useCallback(
    (pageSize) =>
      setPagination((pagination) => ({
        ...pagination,
        pageSize,
        pageIndex: 0,
      })),
    [setPagination]
  );
  const onChangePage = useCallback(
    (pageIndex) =>
      setPagination((pagination) => ({ ...pagination, pageIndex })),
    [setPagination]
  );

  // ** Sorting config
  const [sortingColumns, setSortingColumns] = useState([]);
  const onSort = useCallback(
    (sortingColumns) => {
      setSortingColumns(sortingColumns);
    },
    [setSortingColumns]
  );

  // Column visibility
  const [visibleColumns, setVisibleColumns] = useState(() =>
    columns.map(({ id }) => id)
  ); // initialize to the full set of columns

  function yenile() {
    DataServis.GetRapor()
      .then((sonuc: RaporVM) => {
        setRaporVM(sonuc);
        setPagination({
          pageIndex: 0,
          pageSize: 30,
        });
      })
      .catch((error) => alert(error));
  }

  return (
    <React.Fragment>
      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiPanel style={{ maxWidth: 300 }}>
            <EuiFormRow
              label="Eldeki Araçlar"
              helpText=""
              display="rowCompressed"
            >
              <EuiFieldNumber
                name="eldekiARaclar"
                value={raporVM?.eldekiAracTutari}
                compressed
                readOnly
              />
            </EuiFormRow>
            <EuiFormRow label="Kasa" helpText="" display="rowCompressed">
              <EuiFieldNumber
                name="kasa"
                value={raporVM?.kasa}
                compressed
                readOnly
              />
            </EuiFormRow>
            <EuiFormRow label="Toplam" helpText="" display="rowCompressed">
              <EuiFieldNumber
                name="toplam"
                value={(raporVM?.kasa ?? 0) + (raporVM?.eldekiAracTutari ?? 0)}
                compressed
                readOnly
              />
            </EuiFormRow>

            <EuiFormRow label="Açılış" helpText="" display="rowCompressed">
              <EuiFieldNumber
                name="acilis"
                value={raporVM?.sermaye}
                compressed
                readOnly
              />
            </EuiFormRow>
            <EuiFormRow label="Ofis Gideri" helpText="" display="rowCompressed">
              <EuiFieldNumber
                name="ofisGideri"
                value={raporVM?.ofisGideri}
                compressed
                readOnly
              />
            </EuiFormRow>
            <EuiFormRow
              label="Araç Satış Kârı"
              helpText=""
              display="rowCompressed"
            >
              <EuiFieldNumber
                name="aracKari"
                value={raporVM?.satisKari}
                compressed
                readOnly
              />
            </EuiFormRow>
            <EuiFormRow label="Kâr" helpText="" display="rowCompressed">
              <EuiFieldNumber
                name="kar"
                value={raporVM?.kar}
                compressed
                readOnly
              />
            </EuiFormRow>

            <EuiButton onClick={yenile} fill fullWidth>
              Yenile
            </EuiButton>
          </EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiPanel
            style={{ width: 800, paddingBottom: 5, paddingTop: 40 }}
            paddingSize="none"
          >
            <EuiDataGrid
              aria-label="inMemory level set to sorting data grid demo"
              columns={columns}
              columnVisibility={{ visibleColumns, setVisibleColumns }}
              rowCount={  raporVM?.araclar?.length === undefined
                ? 0
                : raporVM.araclar?.length}
              renderCellValue={({ rowIndex, columnId, isDetails }) => {
                const dt = raporVM?.araclar as Array<any>;
                if (dt.length > 0) {
                  try {
                    const value = dt[rowIndex][columnId];
                    if (columnId === "satildiMi") {
                      return value === true ? "Evet" : "Hayır";
                    } else if (columnId !== "aciklama") {
                      return <EuiI18nNumber value={value} />;
                    }
                    return value;
                  } catch (e) {
                    return "";
                  }
                }
                return "";
              }}
              inMemory={{ level: "sorting" }}
              sorting={{ columns: sortingColumns, onSort }}
              pagination={{
                ...pagination,
                pageSizeOptions: [30],
                onChangeItemsPerPage: onChangeItemsPerPage,
                onChangePage: onChangePage,
              }}
              toolbarVisibility={{
                additionalControls: (
                  <Fragment>
                    <span>Stok:</span>{" "}
                    {
                      raporVM?.araclar.filter((x) => x.satildiMi === false)
                        ?.length
                    }
                    <span>Satılan:</span>{" "}
                    {raporVM?.araclar.filter((x) => x.satildiMi).length}{" "}
                  </Fragment>
                ),
              }}
            />
          </EuiPanel>
          <EuiPanel>
            <EuiTable>
              <EuiTableBody>
                <EuiTableRow>
                  <EuiTableRowCell>Yıl</EuiTableRowCell>
                  <EuiTableRowCell>Ay</EuiTableRowCell>
                  <EuiTableRowCell>Gelir</EuiTableRowCell>
                  <EuiTableRowCell>Gider</EuiTableRowCell>
                  <EuiTableRowCell>Kâr</EuiTableRowCell>
                  <EuiTableRowCell>Araç Başı Kâr</EuiTableRowCell>
                  <EuiTableRowCell>Satış Adet</EuiTableRowCell>
                </EuiTableRow>
                {raporVM?.aylikRapor.map((x) => (
                  <EuiTableRow id={x.yil.toString() + x.ay.toString()}>
                    <EuiTableRowCell>{x.yil}</EuiTableRowCell>
                    <EuiTableRowCell>{x.ay}</EuiTableRowCell>
                    <EuiTableRowCell>{x.gelir}</EuiTableRowCell>
                    <EuiTableRowCell>{x.gider}</EuiTableRowCell>
                    <EuiTableRowCell>{x.kar}</EuiTableRowCell>
                    <EuiTableRowCell>{x.aracBasiKar}</EuiTableRowCell>
                    <EuiTableRowCell>{x.aracAdet}</EuiTableRowCell>
                  </EuiTableRow>
                ))}
              </EuiTableBody>
            </EuiTable>
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
    </React.Fragment>
  );
}
