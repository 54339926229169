import React, { Fragment, useCallback, useEffect, useState } from "react";
import { HareketEntity } from "../../entities/HareketEntity";
import { KalemEntity } from "../../entities/KalemEntity";
import { IslemEntity } from "../../entities/IslemEntity";
import HareketComp from "./HareketComp";

import {
  EuiDataGrid,
  EuiModal,
  EuiModalHeader,
  EuiModalBody,
  EuiButton,
  EuiPanel,
  EuiI18nNumber,
  EuiDatePicker,
  EuiFormRow,
  EuiFlexItem,
  EuiFlexGroup,
  EuiSelect,
} from "@elastic/eui";

import moment from "moment";
import { DataServis } from "../../data/DataServis";

const yeniHareket = {
  rowKey: "",
  aciklama: "",
  islemRowKey: "",
  kalemRowKey: "",
  tarih: null,
  tutar: 0,
  kalem: null,
  islem: null,
};

const columns = [
  {
    id: "rowKey",
  },
  {
    id: "aciklama",
  },
  {
    id: "tarih",
  },
  {
    id: "tutar",
  },
  {
    id: "kalem.aciklama",
  },
  {
    id: "islem.islemAd",
  },
];

export default function HareketPage() {
  const [hareketler, setHareketler] = useState<HareketEntity[]>([]);
  const [hareket, setHareket] = useState<HareketEntity>(yeniHareket);
  const [open, setOpen] = React.useState(false);
  const [tarih, setTarih] = useState(moment().add("day", -7));
  const [tarih2, setTarih2] = useState(moment());
  const [islemRowKey, setIslemRowKey] = useState("");
  const [kalemRowKey, setKalemRowKey] = useState("");

  const [kalemler, setKalemler] = useState<KalemEntity[]>([]);
  const [islemler, setIslemler] = useState<IslemEntity[]>([]);

  useEffect(() => {
    DataServis.GetKalemler(true)
      .then((sonuc: KalemEntity[]) => {
        setKalemler(sonuc);
      })
      .catch((error) => alert(error));

    DataServis.GetIslemler()
      .then((sonuc: IslemEntity[]) => {
        setIslemler(sonuc);
      })
      .catch((error) => alert(error));
  }, []);

  const yenile = () => {
    var t1 = tarih.format("DD-MM-YYYY");
    var t2 = tarih2.format("DD-MM-YYYY");
    DataServis.GetHareketler(t1, t2, islemRowKey, kalemRowKey)
      .then((sonuc: HareketEntity[]) => {
        setHareketler(sonuc);
      })
      .catch((error) => alert(error));
  };

  const hareketClose = () => {
    setOpen(false);
    yenile();
  };

  const hareketOpen = () => {
    yeniKayit();
  };

  const yeniKayit = () => {
    setHareket(yeniHareket);
    setOpen(true);
  };

  const satirSecildi = (id: any) => {
    var model = hareketler.find((x) => x.rowKey === id);

    if (model) {
      setHareket(model);
      setOpen(true);
    }
  };

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 1000,
  });
  const onChangeItemsPerPage = useCallback(
    (pageSize) =>
      setPagination((pagination) => ({
        ...pagination,
        pageSize,
        pageIndex: 0,
      })),
    [setPagination]
  );
  const onChangePage = useCallback(
    (pageIndex) =>
      setPagination((pagination) => ({ ...pagination, pageIndex })),
    [setPagination]
  );

  // ** Sorting config
  const [sortingColumns, setSortingColumns] = useState([]);
  const onSort = useCallback(
    (sortingColumns) => {
      setSortingColumns(sortingColumns);
    },
    [setSortingColumns]
  );

  // Column visibility
  const [visibleColumns, setVisibleColumns] = useState(() =>
    columns.map(({ id }) => id)
  ); // initialize to the full set of columns

  function toplam() {
    let sonuc =
      hareketler?.length > 0 &&
      hareketler?.map((x) => x.tutar).reduce((x, y) => x + y);
    return sonuc > 0 ? <EuiI18nNumber value={sonuc || 0} /> : "";
  }

  return (
    <React.Fragment>
      {open && (
        <EuiModal onClose={hareketClose}>
          <EuiModalHeader></EuiModalHeader>

          <EuiModalBody>
            <HareketComp
              hareket={hareket}
              kalemler={kalemler}
              islemler={islemler}
            />
          </EuiModalBody>
        </EuiModal>
      )}

      <EuiPanel
        style={{ width: 800, paddingBottom: 4, paddingTop: 4 }}
        paddingSize="none"
      >
        <EuiFlexGroup
          style={{ maxWidth: 800, paddingBottom: 10, paddingLeft: 5 }}
        >
          <EuiFlexItem>
            <EuiFormRow label="Tarih1">
              <EuiDatePicker
                selected={tarih}
                onChange={(e) => {
                  if (e !== null) setTarih(e);
                }}
                dateFormat="DD-MM-YYYY"
                locale="de-de"
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFormRow label="Tarih2">
              <EuiDatePicker
                selected={tarih2}
                onChange={(e) => {
                  if (e !== null) setTarih2(e);
                }}
                dateFormat="DD-MM-YYYY"
                locale="de-de"
              />
            </EuiFormRow>
          </EuiFlexItem>

          <EuiFlexItem>
            <EuiFormRow label="Kalem">
              <EuiSelect
                value={kalemRowKey}
                options={[
                  { value: "", text: "Seçiniz" },
                  ...kalemler.map((x: KalemEntity) => {
                    return { value: x.rowKey, text: x.aciklama };
                  }),
                ]}
                onChange={(e) => setKalemRowKey(e.target.value)}
              />
            </EuiFormRow>
          </EuiFlexItem>

          <EuiFlexItem>
            <EuiFormRow label="İşlem">
              <EuiSelect
                value={islemRowKey}
                options={[
                  { value: "", text: "Seçiniz" },
                  ...islemler.map((x: IslemEntity) => {
                    return { value: x.rowKey, text: x.islemAd };
                  }),
                ]}
                onChange={(e) => setIslemRowKey(e.target.value)}
              />
            </EuiFormRow>
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <EuiFormRow hasEmptyLabelSpace display="center">
              <EuiButton onClick={() => yenile()} color="primary" fill>
                Yenile
              </EuiButton>
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiDataGrid
          aria-label="inMemory level set to sorting data grid demo"
          columns={columns}
          columnVisibility={{ visibleColumns, setVisibleColumns }}
          rowCount={hareketler?.length === undefined ? 0 : hareketler?.length}
          renderCellValue={({ rowIndex, columnId, isDetails }) => {
            const dt = hareketler as Array<any>;

            if (dt.length > 0) {
              try {
                const value = dt[rowIndex][columnId];
                if (columnId === "rowKey") {
                  return (
                    <EuiButton
                      onClick={() => {
                        satirSecildi(value);
                      }}
                    >
                      Düzenle
                    </EuiButton>
                  );
                } else if (columnId === "aracMi") {
                  return value === true ? "Evet" : "Hayır";
                } else if (columnId.indexOf(".") > -1) {
                  var parcalar = columnId.split(".");

                  var objValue = dt[rowIndex][parcalar[0]];

                  var obj = objValue as any;
                  var sonuc = obj[parcalar[1]];

                  return sonuc;
                } else if (columnId === "tarih") {
                  return moment(value, "YYYY-MM-DD").format("DD-MM-YYYY");
                } else if (columnId === "tutar") {
                  return <EuiI18nNumber value={value} />;
                }

                return value ?? "";
              } catch (e) {
                return "";
              }
            }
            return "";
          }}
          inMemory={{ level: "sorting" }}
          sorting={{ columns: sortingColumns, onSort }}
          pagination={{
            ...pagination,
            pageSizeOptions: [1000],
            onChangeItemsPerPage: onChangeItemsPerPage,
            onChangePage: onChangePage,
          }}
          toolbarVisibility={{
            additionalControls: (
              <Fragment>
                <EuiButton
                  onClick={hareketOpen}
                  size="s"
                  color="secondary"
                  fill
                >
                  Yeni Hareket
                </EuiButton>
              </Fragment>
            ),
          }}
          renderFooterCellValue={({ columnId }) =>
            columnId === "tutar" ? toplam() : null
          }
        />
      </EuiPanel>
    </React.Fragment>
  );
}
