import { Giris } from "../entities/Giris";
import { HareketEntity } from "../entities/HareketEntity";
import { IslemEntity } from "../entities/IslemEntity";
import { KalemEntity } from "../entities/KalemEntity";
import { RaporVM } from "../entities/RaporVM";
import Api from "./Api";

export const DataServis = {
  GetIslemler: async function () {
    const response = await Api.get<IslemEntity[]>("islem");
    const sonuc = response.data;
    return sonuc;
  },

  SaveIslem: async function (model: IslemEntity) {
    const response =
      model.rowKey.length > 0
        ? await Api.put<IslemEntity>("islem", model)
        : await Api.post<IslemEntity>("islem", model);

    const sonuc = response.data;
    return sonuc;
  },

  GetKalemler: async function (aktifMi: boolean) {
    const ek = aktifMi ? "?aktifMi=1" : "";
    const response = await Api.get<KalemEntity[]>("kalem" + ek);

    const sonuc = response.data;

    return sonuc;
  },

  SaveKalem: async function (model: KalemEntity) {
    const response =
      model.rowKey.length > 0
        ? await Api.put<KalemEntity>("kalem", model)
        : await Api.post<KalemEntity>("kalem", model);

    const sonuc = response.data;
    return sonuc;
  },

  GetHareketler: async function (
    tarih: string,
    tarih2: string,
    islemRowKey: string,
    kalemRowKey: string
  ) {
    const response = await Api.get<HareketEntity[]>(
      "hareket?tarih=" +
        tarih +
        "&tarih2=" +
        tarih2 +
        "&islemRowKey=" +
        islemRowKey +
        "&kalemRowKey=" +
        kalemRowKey
    );
    const sonuc = response.data;
    return sonuc;
  },

  SaveHareket: async function (model: HareketEntity) {
    const response =
      model.rowKey?.length > 0
        ? await Api.put<HareketEntity>("hareket", model)
        : await Api.post<HareketEntity>("hareket", model);

    const sonuc = response.data;
    return sonuc;
  },

  GetRapor: async function () {
    const response = await Api.get<RaporVM>("rapor");
    const sonuc = response.data;
    return sonuc;
  },

  GirisYap: async function (model: Giris) {
    const response = await Api.post<Giris>("giris", model);

    const sonuc = response.data;
    return sonuc;
  },

  CikisYap: async function () {
    const response = await Api.delete<string>("giris", {});

    const sonuc = response.data;
    return sonuc;
  },
};
