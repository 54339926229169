import axios from "axios";
import moment from "moment";

const API_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:7071/api/"
    : "https://autokaazurefunction.azurewebsites.net/api/";

const instance = axios.create({
  baseURL: API_URL,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + getTicket(),
  },

  transformResponse: [
    (data) => {
      const sou = jsonDates.parse(data);
      return sou;
    },
  ],
});

function getTicket() {
  return localStorage.getItem("loginTicket");
}
var jsonDates = {
  dtrx2: /\d{4}-\d{2}-\d{2}/,
  parse: function (obj: any) {
    return this.parseDates(obj);
  },
  parseDates: function (obj: any) {
    // iterate properties
    for (let pName in obj) {
      // make sure the property is 'truthy'
      if (obj[pName]) {
        var value = obj[pName];
        // determine if the property is an array
        if (Array.isArray(value)) {
          for (var ii = 0; ii < value.length; ii++) {
            this.parseDates(value[ii]);
          }
        }
        // determine if the property is an object
        else if (typeof value == "object") {
          this.parseDates(value);
        }
        // determine if the property is a string containing a date
        else if (typeof value == "string" && this.dtrx2.test(value)) {
          // parse and replace

          let x = moment.utc(value);
          obj[pName] = x;
          //obj[pName] = new Date(obj[pName]);
        }
      }
    }

    return obj;
  },
};

instance.interceptors.response.use((config) => {
  return config;
});

instance.interceptors.request.use(
  (config) => {
    const token = getTicket();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    // config.headers['Content-Type'] = 'application/json';
    config.url +=
      (config.url && config.url.indexOf("?") > -1 ? "&" : "?") +
      "code=jaoAcjBwm0LFay8A4tyv8v7snUb1ZQfAJ/lYQKFqrAwqyADqxtPhJg==";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default instance;
