import React, { useState } from "react";

import { KalemEntity } from "../../entities/KalemEntity";

import {
  EuiFieldText,
  EuiFormRow,
  EuiPanel,
  EuiCheckbox,
  EuiButton,
} from "@elastic/eui";
import { DataServis } from "../../data/DataServis";

export interface KalemProps {
  kalem: KalemEntity;
}

export default function KalemComp(model: KalemProps) {
  const [kalem, setKalem] = useState<KalemEntity>(model.kalem);

  const handleSave = () => {
    DataServis.SaveKalem(kalem)
      .then((sonuc: KalemEntity) => {
        setKalem(sonuc);
        alert("Kayıt yapıldı");
      })
      .catch((error) => alert(error));
  };

  return (
    <React.Fragment>
      <EuiPanel style={{ maxWidth: 300 }}>
        <EuiFormRow label="Araç Mı?" display="columnCompressed">
          <EuiCheckbox
            id="arabaMi"
            checked={kalem.aracMi}
            onChange={(e: any) => setKalem({ ...kalem, aracMi: !kalem.aracMi })}
          />
        </EuiFormRow>

        <EuiFormRow label="Açıklama" helpText="" display="columnCompressed">
          <EuiFieldText
            name="Açıklama"
            value={kalem.aciklama}
            onChange={(e: any) =>
              setKalem({ ...kalem, aciklama: e.target.value })
            }
            compressed
          />
        </EuiFormRow>

        <EuiFormRow label="Plaka" helpText="" display="columnCompressed">
          <EuiFieldText
            disabled={!kalem.aracMi}
            name="Plaka"
            value={kalem.plaka}
            onChange={(e: any) => setKalem({ ...kalem, plaka: e.target.value })}
            compressed
          />
        </EuiFormRow>

        <EuiFormRow label="Marka" helpText="" display="columnCompressed">
          <EuiFieldText
            disabled={!kalem.aracMi}
            name="Marka"
            value={kalem.marka}
            onChange={(e: any) => setKalem({ ...kalem, marka: e.target.value })}
            compressed
          />
        </EuiFormRow>

        <EuiFormRow label="Model" helpText="" display="columnCompressed">
          <EuiFieldText
            disabled={!kalem.aracMi}
            name="Model"
            value={kalem.model}
            onChange={(e: any) => setKalem({ ...kalem, model: e.target.value })}
            compressed
          />
        </EuiFormRow>

        <EuiFormRow label="Satıldı Mı?" display="columnCompressed">
          <EuiCheckbox
            id="satildiMi"
            checked={kalem.pasifMi}
            onChange={(e: any) =>
              setKalem({ ...kalem, pasifMi: !kalem.pasifMi })
            }
          />
        </EuiFormRow>

        <EuiButton onClick={handleSave} fill fullWidth>
          Kaydet
        </EuiButton>
      </EuiPanel>
    </React.Fragment>
  );
}
