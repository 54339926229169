import React, { useState } from "react";

import { IslemEntity } from "../../entities/IslemEntity";

import {
  EuiFieldText,
  EuiFormRow,
  EuiPanel,
  EuiCheckbox,
  EuiButton,
} from "@elastic/eui";
import { DataServis } from "../../data/DataServis";

export interface IslemProps {
  Islem: IslemEntity;
}

export default function IslemComp(model: IslemProps) {
  const [islem, setIslem] = useState<IslemEntity>(model.Islem);

  const handleSave = () => {
    DataServis.SaveIslem(islem)
      .then((sonuc: IslemEntity) => {
        setIslem(sonuc);
        alert("Kayıt yapıldı");
      })
      .catch((error) => alert(error));
  };

  return (
    <React.Fragment>
      <EuiPanel style={{ maxWidth: 300 }}>
        <EuiFormRow label="İşlem Ad" helpText="" display="columnCompressed">
          <EuiFieldText
            name="Ad"
            value={islem.islemAd}
            onChange={(e: any) =>
              setIslem({ ...islem, islemAd: e.target.value })
            }
            compressed
          />
        </EuiFormRow>

        <EuiFormRow label="Araç için mi?" display="columnCompressed">
          <EuiCheckbox
            id="aracIcinMi"
            checked={islem.aracIcinMi}
            onChange={(e: any) =>
              setIslem({ ...islem, aracIcinMi: !islem.aracIcinMi })
            }
          />
        </EuiFormRow>
        <EuiFormRow label="Gelir mi?" display="columnCompressed">
          <EuiCheckbox
            id="gelirMi"
            checked={islem.gelirMi}
            onChange={(e: any) =>
              setIslem({ ...islem, gelirMi: !islem.gelirMi })
            }
          />
        </EuiFormRow>

        <EuiButton onClick={handleSave} fill fullWidth>
          Kaydet
        </EuiButton>
      </EuiPanel>
    </React.Fragment>
  );
}
