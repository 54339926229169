import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  EuiDataGrid,
  EuiModal,
  EuiModalHeader,
  EuiModalBody,
  EuiButton,
  EuiModalHeaderTitle,
  EuiPanel,
} from "@elastic/eui";
import FiltreComp from "./FiltreComp";
import { FiltreEntity } from "../../../entities/arababul/FiltreEntity";
import { ArabaBulDataServis } from "../../../data/ArabaBulDataServis";

const columns = [
  {
    id: "rowKey",
  },
  {
    id: "ad",
  },
  {
    id: "filtre",
  },
  {
    id: "kayitSayi",
  },
];

const bosKayit = {
  ad: "",
  rowKey: "",
  filtre: "",
  kayitSayi: 0,
};

export default function KalemPage() {
  const [filtreler, setFiltreler] = useState<FiltreEntity[]>([]);

  const [filtre, setFiltre] = useState<FiltreEntity>(bosKayit);
  const [open, setOpen] = React.useState(false);

  const filtreOpen = () => {
    yeniKayit();
  };

  const filtreClose = () => {
    setOpen(false);
    yenile();
  };

  const yeniKayit = () => {
    setFiltre(bosKayit);
    setOpen(true);
  };

  const satirSecildi = (id: any) => {
    var model = filtreler.find((x) => x.rowKey === id);
    if (model) {
      setFiltre(model);
      setOpen(true);
    }
  };

  const yenile = () => {
    ArabaBulDataServis.GetFiltreler()
      .then((sonuc: FiltreEntity[]) => {
        setFiltreler(sonuc);
      })
      .catch((error) => alert(error));
  };

  useEffect(() => {
    yenile();
  }, []);

  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 });
  const onChangeItemsPerPage = useCallback(
    (pageSize) =>
      setPagination((pagination) => ({
        ...pagination,
        pageSize,
        pageIndex: 0,
      })),
    [setPagination]
  );
  const onChangePage = useCallback(
    (pageIndex) =>
      setPagination((pagination) => ({ ...pagination, pageIndex })),
    [setPagination]
  );

  // ** Sorting config
  const [sortingColumns, setSortingColumns] = useState([]);
  const onSort = useCallback(
    (sortingColumns) => {
      setSortingColumns(sortingColumns);
    },
    [setSortingColumns]
  );

  // Column visibility
  const [visibleColumns, setVisibleColumns] = useState(() =>
    columns.map(({ id }) => id)
  ); // initialize to the full set of columns

  return (
    <>
      {open && (
        <EuiModal onClose={filtreClose}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>Filtre</EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            <FiltreComp filtre={filtre} />
          </EuiModalBody>
        </EuiModal>
      )}

      <EuiPanel
        style={{ width: 800, paddingBottom: 4, paddingTop: 40 }}
        paddingSize="none"
      >
        <EuiDataGrid
          aria-label="inMemory level set to sorting data grid demo"
          columns={columns}
          columnVisibility={{ visibleColumns, setVisibleColumns }}
          rowCount={filtreler?.length === undefined ? 0 : filtreler?.length}
          renderCellValue={({ rowIndex, columnId, isDetails }) => {
            const dt = filtreler as Array<any>;
            if (dt.length > 0) {
              try {
                var id = columnId;
                const value = dt[rowIndex][id];
                if (id === "rowKey") {
                  return (
                    <EuiButton
                      onClick={() => {
                        satirSecildi(value);
                      }}
                    >
                      Düzenle
                    </EuiButton>
                  );
                }
                return value;
              } catch (e) {
                return "";
              }
            }
            return "";
          }}
          inMemory={{ level: "sorting" }}
          sorting={{ columns: sortingColumns, onSort }}
          pagination={{
            ...pagination,
            pageSizeOptions: [50, 10, 200],
            onChangeItemsPerPage: onChangeItemsPerPage,
            onChangePage: onChangePage,
          }}
          toolbarVisibility={{
            additionalControls: (
              <Fragment>
                <EuiButton onClick={filtreOpen} size="s" color="secondary" fill>
                  Yeni Filtre
                </EuiButton>
              </Fragment>
            ),
          }}
        />
      </EuiPanel>
    </>
  );
}
