import React, { useEffect, useState } from "react";

import { HareketEntity } from "../../entities/HareketEntity";
import { KalemEntity } from "../../entities/KalemEntity";
import { IslemEntity } from "../../entities/IslemEntity";

import {
  EuiFieldText,
  EuiFormRow,
  EuiSelect,
  EuiPanel,
  EuiButton,
  EuiFieldNumber,
  EuiDatePicker,
  EuiCallOut,
  EuiFlexItem,
  EuiFlexGroup,
} from "@elastic/eui";
import { DataServis } from "../../data/DataServis";

export interface HareketProps {
  hareket: HareketEntity;
  kalemler: KalemEntity[];
  islemler: IslemEntity[];
}

export default function HareketComp(model: HareketProps) {
  const [hareket, setHareket] = useState<HareketEntity>(model.hareket);
  const [hataVarMi, setHataVarMi] = useState(0);
  const [hatalar, setHatalar] = useState<Array<string>>([]);

  const handleYeni = () => {
    setHareket({
      ...model.hareket,
      rowKey: "",
      tutar: 0,
      aciklama: "",
      islem: null,
      islemRowKey: "",
      tarih: hareket.tarih,
      kalemRowKey: hareket.kalemRowKey,
      kalem: hareket.kalem,
    });
  };

  const handleSave = () => {
    let h = { ...hareket };
    h.kalem = null;
    h.islem = null;

    if (h.rowKey?.length <= 0) {
      //h.tarih = h.tarih?.add(1, "day") || h.tarih;
    }

    let lHatalar: Array<string> = [];

    if (h.tarih === null) {
      lHatalar.push("Tarih");
    }

    if (h.kalemRowKey?.length <= 0) {
      lHatalar.push("Kalem");
    }

    if (h.islemRowKey?.length <= 0) {
      lHatalar.push("İşlem");
    }

    if (h.tutar <= 0) {
      lHatalar.push("Tutar");
    }

    setHatalar(lHatalar);

    if (lHatalar.length === 0) {
      DataServis.SaveHareket(h)
        .then((sonuc: HareketEntity) => {
          setHareket(sonuc);
          setHataVarMi(1);
        })
        .catch((error) => {
          setHataVarMi(2);
          alert(error);
        });
    } else {
      setHataVarMi(2);
    }
  };

  useEffect(() => {});

  return (
    <EuiPanel style={{ maxWidth: 300 }}>
      {hataVarMi === 1 && (
        <EuiCallOut
          title="Kayıt yapıldı"
          color="success"
          iconType="user"
        ></EuiCallOut>
      )}

      {hataVarMi === 2 && (
        <EuiCallOut title="Hatalar var." color="danger" iconType="alert">
          {hatalar.map((x) => (
            <p>{x}</p>
          ))}
        </EuiCallOut>
      )}

      <EuiFormRow label="Tarih" display="columnCompressed">
        <EuiDatePicker
          selected={hareket.tarih}
          onChange={(e) => {
            setHareket({ ...hareket, tarih: e });
          }}
          dateFormat="DD-MM-YYYY"
          locale="tr-TR"
        />
      </EuiFormRow>

      <EuiFormRow label="Kalem" display="columnCompressed">
        <EuiSelect
          value={hareket.kalemRowKey}
          options={[
            { value: 0, text: "Seçiniz" },
            ...model.kalemler.map((x: KalemEntity) => {
              return { value: x.rowKey, text: x.aciklama };
            }),
          ]}
          onChange={(e) =>
            setHareket({ ...hareket, kalemRowKey: e.target.value })
          }
          compressed
        />
      </EuiFormRow>

      <EuiFormRow label="İşlem" display="columnCompressed">
        <EuiSelect
          value={hareket.islemRowKey}
          options={[
            { value: 0, text: "Seçiniz" },
            ...model.islemler
              .filter(
                (x) =>
                  x.aracIcinMi ===
                  model.kalemler.find((y) => y.rowKey === hareket.kalemRowKey)
                    ?.aracMi
              )
              .map((x: IslemEntity) => {
                return { value: x.rowKey, text: x.islemAd };
              }),
          ]}
          onChange={(e) =>
            setHareket({ ...hareket, islemRowKey: e.target.value })
          }
          compressed
        />
      </EuiFormRow>

      <EuiFormRow label="Tutar" helpText="" display="columnCompressed">
        <EuiFieldNumber
          name="Tutar"
          value={hareket.tutar}
          onChange={(e: any) =>
            setHareket({ ...hareket, tutar: e.target.value })
          }
          compressed
        />
      </EuiFormRow>

      <EuiFormRow label="Açıklama" helpText="" display="columnCompressed">
        <EuiFieldText
          name="Açıklama"
          value={hareket.aciklama}
          onChange={(e: any) =>
            setHareket({ ...hareket, aciklama: e.target.value })
          }
          compressed
        />
      </EuiFormRow>
      <EuiFlexGroup
        wrap
        gutterSize="s"
        alignItems="center"
        style={{ marginTop: 10 }}
      >
        <EuiFlexItem grow={false}>
          <EuiButton onClick={handleSave} fill color="primary">
            Kaydet
          </EuiButton>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButton onClick={handleYeni} fill color="secondary">
            Yeni
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  );
}
