import React, { useState } from "react";
import {
  EuiFieldText,
  EuiFormRow,
  EuiPanel,
  EuiButton,
  EuiFieldNumber,
  EuiFlexItem,
  EuiFlexGroup,
} from "@elastic/eui";
import { FiltreEntity } from "../../../entities/arababul/FiltreEntity";
import { ArabaBulDataServis } from "../../../data/ArabaBulDataServis";

export interface FiltreProps {
  filtre: FiltreEntity;
}

export default function KalemComp(model: FiltreProps) {
  const [filtre, setFiltre] = useState<FiltreEntity>(model.filtre);

  const handleSave = () => {
    ArabaBulDataServis.SaveFiltre(filtre)
      .then((sonuc: FiltreEntity) => {
        setFiltre(sonuc);
        alert("Kayıt yapıldı");
      })
      .catch((error) => alert(error));
  };

  const handleDelete = () => {
    ArabaBulDataServis.DeleteFiltre(filtre.rowKey)
      .then((sonuc: any) => {
        alert("Kayıt silindi.");
      })
      .catch((error) => alert(error));
  };
  return (
    <React.Fragment>
      <EuiPanel style={{ maxWidth: 300 }}>
        <EuiFormRow label="Ad" helpText="" display="columnCompressed">
          <EuiFieldText
            name="ad"
            value={filtre.ad}
            onChange={(e: any) => setFiltre({ ...filtre, ad: e.target.value })}
            compressed
          />
        </EuiFormRow>

        <EuiFormRow label="Filtre" helpText="" display="columnCompressed">
          <EuiFieldText
            name="ad"
            value={filtre.filtre}
            onChange={(e: any) =>
              setFiltre({ ...filtre, filtre: e.target.value })
            }
            compressed
          />
        </EuiFormRow>

        <EuiFormRow label="Kayıt Sayı" helpText="" display="columnCompressed">
          <EuiFieldNumber
            name="kayitSayi"
            value={filtre.kayitSayi}
            onChange={(e: any) =>
              setFiltre({ ...filtre, kayitSayi: e.target.value })
            }
            compressed
          />
        </EuiFormRow>

        <EuiFlexGroup
          wrap
          gutterSize="s"
          alignItems="center"
          style={{ marginTop: 10 }}
        >
          <EuiFlexItem grow={false}>
            <EuiButton onClick={handleSave} color="primary" fill>
              Kaydet
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton onClick={handleDelete} color="danger" fill>
              Sil
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPanel>
    </React.Fragment>
  );
}
