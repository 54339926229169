import React, { Fragment, useCallback, useEffect, useState } from "react";
import { KalemEntity } from "../../entities/KalemEntity";

import {
  EuiDataGrid,
  EuiModal,
  EuiModalHeader,
  EuiModalBody,
  EuiButton,
  EuiModalHeaderTitle,
  EuiPanel,
} from "@elastic/eui";
import KalemComp from "./KalemComp";

import { DataServis } from "../../data/DataServis";

const columns = [
  {
    id: "rowKey",
  },
  {
    id: "aracMi",
  },
  {
    id: "aciklama",
  },
  {
    id: "plaka",
  },
  {
    id: "marka",
  },
  {
    id: "model",
  },
  {
    id: "satildiMi",
  },
];

export default function KalemPage() {
  const [kalemler, setKalemler] = useState<KalemEntity[]>([]);

  const [kalem, setKalem] = useState<KalemEntity>({
    aciklama: "",
    aracMi: true,
    rowKey: "",
    marka: "",
    model: "",
    pasifMi: false,
    plaka: "",
  });
  const [open, setOpen] = React.useState(false);

  const kalemOpen = () => {
    yeniKayit();
  };

  const kalemClose = () => {
    setOpen(false);
    yenile();
  };

  const yeniKayit = () => {
    setKalem({
      aciklama: "",
      aracMi: true,
      rowKey: "",
      marka: "",
      model: "",
      pasifMi: false,
      plaka: "",
    });
    setOpen(true);
  };

  const satirSecildi = (id: any) => {
    var model = kalemler.find((x) => x.rowKey === id);
    if (model) {
      setKalem(model);
      setOpen(true);
    }
  };

  const yenile = () => {
    DataServis.GetKalemler(false)
      .then((sonuc: KalemEntity[]) => {
        setKalemler(sonuc);
      })
      .catch((error) => alert(error));
  };

  useEffect(() => {
    yenile();
  }, []);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 1000,
  });
  const onChangeItemsPerPage = useCallback(
    (pageSize) =>
      setPagination((pagination) => ({
        ...pagination,
        pageSize,
        pageIndex: 0,
      })),
    [setPagination]
  );
  const onChangePage = useCallback(
    (pageIndex) =>
      setPagination((pagination) => ({ ...pagination, pageIndex })),
    [setPagination]
  );

  // ** Sorting config
  const [sortingColumns, setSortingColumns] = useState([]);
  const onSort = useCallback(
    (sortingColumns) => {
      setSortingColumns(sortingColumns);
    },
    [setSortingColumns]
  );

  // Column visibility
  const [visibleColumns, setVisibleColumns] = useState(() =>
    columns.map(({ id }) => id)
  ); // initialize to the full set of columns
  return (
    <>
      {open && (
        <EuiModal onClose={kalemClose}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>Kalem</EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            <KalemComp kalem={kalem} />
          </EuiModalBody>
        </EuiModal>
      )}

      <EuiPanel
        style={{ width: 800, paddingBottom: 4, paddingTop: 40 }}
        paddingSize="none"
      >
        <EuiDataGrid
          aria-label="inMemory level set to sorting data grid demo"
          columns={columns}
          columnVisibility={{ visibleColumns, setVisibleColumns }}
          rowCount={kalemler?.length === undefined ? 0 : kalemler?.length}
          renderCellValue={({ rowIndex, columnId, isDetails }) => {
            const dt = kalemler as Array<any>;
            if (dt.length > 0) {
              try {
                var id = columnId === "satildiMi" ? "pasifMi" : columnId;
                const value = dt[rowIndex][id];
                if (id === "rowKey") {
                  return (
                    <EuiButton
                      onClick={() => {
                        satirSecildi(value);
                      }}
                    >
                      Düzenle
                    </EuiButton>
                  );
                } else if (id === "aracMi" || id === "pasifMi") {
                  return value === true ? "Evet" : "Hayır";
                }
                return value;
              } catch (e) {
                return "";
              }
            }
            return "";
          }}
          inMemory={{ level: "sorting" }}
          sorting={{ columns: sortingColumns, onSort }}
          pagination={{
            ...pagination,
            pageSizeOptions: [1000],
            onChangeItemsPerPage: onChangeItemsPerPage,
            onChangePage: onChangePage,
          }}
          toolbarVisibility={{
            additionalControls: (
              <Fragment>
                <EuiButton onClick={kalemOpen} size="s" color="secondary" fill>
                  Yeni Kalem
                </EuiButton>
              </Fragment>
            ),
          }}
        />
      </EuiPanel>
    </>
  );
}
