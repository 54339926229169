import React, { createContext, useContext, useEffect, useState } from "react";

import {
  BrowserRouter,
  Switch,
  Route,
  useHistory,
  Redirect,
} from "react-router-dom";
import "moment/locale/tr";

import HareketPage from "./components/hareket/HareketPage";
import KalemPage from "./components/kalem/KalemPage";
import AnaSayfaPage from "./components/anasayfa/AnaSayfaPage";
import IslemPage from "./components/islem/IslemPage";

import {
  EuiFieldText,
  EuiFormRow,
  EuiPanel,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiCallOut,
  EuiPageTemplate,
} from "@elastic/eui";
import { DataServis } from "./data/DataServis";
import { Giris } from "./entities/Giris";
import RaporPage from "./components/rapor/RaporPage";
import moment from "moment";
import FiltrePage from "./components/arababul/filtre/FiltrePage";
moment().locale("tr");

export function MenuList() {
  const history = useHistory();

  let auth = useAuth();

  function cikis() {
    auth.signout(() => history.push("/"));
  }

  function hareketlereGit() {
    history.push("/hareket");
  }

  function kalemlereGit() {
    history.push("/kalem");
  }

  function raporlaraGit() {
    history.push("/rapor");
  }

  function filtrelereGit() {
    history.push("/filtre");
  }

  function islemlereGit() {
    history.push("/islem");
  }

  return (
    <>
      <EuiFlexGroup gutterSize="s" alignItems="center">
        <EuiFlexItem grow={false}>
          <EuiButton
            color="primary"
            size="s"
            onClick={() => {
              hareketlereGit();
            }}
            fill
          >
            Hareketler
          </EuiButton>
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <EuiButton
            size="s"
            color="danger"
            onClick={() => {
              kalemlereGit();
            }}
            fill
          >
            Kalemler
          </EuiButton>
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <EuiButton
            size="s"
            color="text"
            onClick={() => {
              raporlaraGit();
            }}
            fill
          >
            Rapor
          </EuiButton>
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <EuiButton
            size="s"
            color="primary"
            onClick={() => {
              islemlereGit();
            }}
            fill
          >
            İşlem
          </EuiButton>
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <EuiButton
            size="s"
            color="secondary"
            onClick={() => {
              filtrelereGit();
            }}
            fill
          >
            Filtre
          </EuiButton>
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <EuiButton
            size="s"
            color="warning"
            onClick={() => {
              cikis();
            }}
            fill
          >
            Çıkış Yap
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
}

export default function App() {
  return (
    <ProvideAuth>
      <React.Fragment>
        <BrowserRouter>
          <EuiPageTemplate
            template="centeredContent"
            pageContentProps={{ paddingSize: "none" }}
            pageHeader={{
              iconType: "logoElastic",
              pageTitle: "AutoKA Motors",
              rightSideItems: [<AuthButton />],
            }}
          >
            <Switch>
              <PrivateRoute path="/kalem">
                <KalemPage />
              </PrivateRoute>
              <PrivateRoute path="/hareket">
                <HareketPage />
              </PrivateRoute>
              <PrivateRoute path="/rapor">
                <RaporPage />
              </PrivateRoute>
              <PrivateRoute path="/islem">
                <IslemPage />
              </PrivateRoute>
              <Route path="/giris">
                <GirisPage />
              </Route>

              <Route path="/filtre">
                <FiltrePage />
              </Route>
              <PrivateRoute path="/">
                <AnaSayfaPage />
              </PrivateRoute>
            </Switch>
          </EuiPageTemplate>
        </BrowserRouter>
      </React.Fragment>
    </ProvideAuth>
  );
}

const fakeAuth = {
  isAuthenticated: false,
  signin(cb: any) {
    fakeAuth.isAuthenticated = true;
    setTimeout(cb, 100); // fake async
  },
  signout(cb: any) {
    fakeAuth.isAuthenticated = false;
    setTimeout(cb, 100);
  },
};

/** For more details on
 * `authContext`, `ProvideAuth`, `useAuth` and `useProvideAuth`
 * refer to: https://usehooks.com/useAuth/
 */
const authContext = createContext({
  user: "",
  signin: function (cb: any) {},
  signout: function (cb: any) {},
});

function ProvideAuth({ children }: any) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

function useAuth() {
  return useContext(authContext);
}

function useProvideAuth() {
  const [user, setUser] = useState(localStorage.getItem("loginTicket") ?? "");

  const signin = (cb: any) => {
    return fakeAuth.signin(() => {
      setUser(localStorage.getItem("loginTicket") ?? "");
      cb();
    });
  };

  const signout = (cb: any) => {
    return fakeAuth.signout(() => {
      DataServis.CikisYap();
      localStorage.removeItem("loginTicket");
      setUser("");
      cb();
    });
  };

  return {
    user,
    signin,
    signout,
  };
}

function AuthButton() {
  let auth = useAuth();

  return auth.user ? <MenuList /> : <p></p>;
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }: any) {
  let auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/giris",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function GirisPage() {
  const history = useHistory();

  let auth = useAuth();
  const [giris, setGiris] = useState<Giris>({
    kullaniciAd: "",
    loginTicket: "",
    sifre: "",
  });
  const [hataVarMi, setHataVarMi] = useState(false);
  const [hata, setHata] = useState("");

  const handleGiris = () => {
    DataServis.GirisYap(giris)
      .then((sonuc: Giris) => {
        if (sonuc.loginTicket.length > 5) {
          localStorage.setItem("loginTicket", sonuc.loginTicket);
          git();
        }
      })
      .catch((error) => {
        setHata(error.message);
        setHataVarMi(true);
      });
  };

  function git() {
    auth.signin(() => {
      history.push("/");
    });
  }

  useEffect(() => {
    console.log("test");
  });

  return (
    <EuiPanel style={{ maxWidth: 600, minWidth: 350 }}>
      {hataVarMi && (
        <EuiCallOut title={hata} color="danger" iconType="alert"></EuiCallOut>
      )}

      <EuiFormRow label="Kullanıcı Ad" helpText="" display="rowCompressed">
        <EuiFieldText
          name="KullaniniciAd"
          value={giris.kullaniciAd}
          onChange={(e: any) =>
            setGiris({ ...giris, kullaniciAd: e.target.value })
          }
          compressed
        />
      </EuiFormRow>

      <EuiFormRow label="Şifre" helpText="" display="rowCompressed">
        <EuiFieldText
          name="Şifre"
          type="password"
          value={giris.sifre}
          onChange={(e: any) => setGiris({ ...giris, sifre: e.target.value })}
          compressed
        />
      </EuiFormRow>

      <EuiButton onClick={handleGiris} fill fullWidth>
        Giriş Yap
      </EuiButton>
    </EuiPanel>
  );
}
